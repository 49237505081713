import * as React from "react"
import Layout from "../templates/layout";
import FullApp from "../FullApp";
import Panel from "../components/panel";
import OrderHistory from "../components/panel/pages/order-history";

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Panel {...props}>
        <OrderHistory {...props}/>
      </Panel>
    </Layout>
  </FullApp>
);