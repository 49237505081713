import * as React from "react";
import {IUser} from "../types";
import {Button, Container, Grid, Header, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import * as actions from "../actions";
import {navigateTo} from "../helpers/router";
import {Link} from "gatsby";
import '../css/panel.css'
import Cloud from './backgrounds/others/Cloud';

interface StateProps {
  user: IUser
}

export function mapStateToProps(state: any): StateProps {
  return {
    user: state.user,
  };
}

interface DispatchProps {
  showLogin: typeof actions.showLogin
  showRegister: typeof actions.showRegister
}

const mapDispatchToProps = {
  showLogin: actions.showLogin,
  showRegister: actions.showRegister
}

type Props = StateProps & DispatchProps;

interface State {
  loading: boolean,
  isLogged: boolean,
  statements: boolean,
}

class Panel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLogged: false,
      loading: true,
      statements: false,
    };
  }

  componentDidMount(): void {
    this.onUserChange(this.props.user);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.user !== this.props.user) {
      this.onUserChange(this.props.user);
    }
  }

  onUserChange(user) {
    if (user) {
      if (user.data) {
        this.setState({isLogged: true});
      } else {
        this.setState({loading: false});
      }
    }
  }

  render() {
    if (this.state.isLogged) {
      return (<>{this.props.children}</>);
    } else if(this.state.loading) {
      return (<Segment loading={true}/>);
    } else {
      return (
      <Cloud>
        <Segment basic id="cloud-panel" style={{height: "calc(100vh - 10px)"}}>
          <Container id="cloud-container">
            <Grid stackable textAlign="center">
              <Grid.Column>
                <div>
                  <Header id="cloud-header">Ups...</Header>
                  <Header id="cloud-subheader">Ta strona dostępna jest tylko dla zalogowanych użytkowników</Header>
                  <Button primary className='btn-primary shadow-light' id='btn-register' onClick={()=> navigateTo('/login')}>zaloguj się</Button>
                  <p>Nie masz jeszcze konta?</p>
                  <Link to="/register"><p className='link'>Zarejestruj się</p></Link>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </Segment>
      </Cloud>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Panel);