import * as React from "react";
import {Helmet} from "react-helmet"
import {Container, Header, Segment, Grid, Divider, Button} from "semantic-ui-react";
import * as actions from "../../../actions";
import {connect} from "react-redux";
import _ from 'lodash';
import {IUser, IHistoryEvents, IOrder} from "../../../types";
import {t} from "../../../i18n";
import {toast} from "react-toastify";
import {navigateTo} from "../../../helpers/router";
import MainTopBg from "../../backgrounds/others/MainTopBg";

interface StateProps {
  user: IUser,
  orders: Array<IOrder>,
  events: IHistoryEvents,
  cancelResponse: any
  retry: any,
  setActive: any
  createOrder: any
}

function mapStateToProps(state: any) {
  return {
    user: state.user,
    orders: state.getOrders,
    cancelResponse: state.cancelOrder,
    retry: state.retryPayuPayment,
    setActive: state.setActiveUserOrder,
    createOrder: state.createOrderByOrder,
  };
}

interface DispatchProps {
  getOrders: typeof actions.getOrders,
  cancelOrder: typeof actions.cancelOrder,
  createOrderByOrder: typeof actions.createOrderByOrder,
  retryPayuPayment: typeof actions.retryPayuPayment,
  setActiveUserOrder: typeof actions.setActiveUserOrder,
}

const mapDispatchToProps = {
  getOrders: actions.getOrders,
  cancelOrder: actions.cancelOrder,
  createOrderByOrder: actions.createOrderByOrder,
  retryPayuPayment: actions.retryPayuPayment,
  setActiveUserOrder: actions.setActiveUserOrder,
}

type Props = StateProps & DispatchProps;

interface State {
  user: IUser
  orders: Array<IOrder>
  isEmpty: boolean
  loading: boolean
}

class OrderHistory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      user: null,
      orders: null,
      isEmpty: null,
      loading: true
    }
  }

  componentDidMount(): void {
    this.props.getOrders(this.props.user.data.id);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.orders !== this.props.orders) {
      this.setState({loading: false})
      let orders = this.props.orders;
      if (orders.data.length > 0) {
        this.setState({orders: orders.data})
      } else {
        this.setState({isEmpty: true})
      }
    }
    if (prevProps.cancelResponse !== this.props.cancelResponse) {
      this.props.getOrders(this.props.user.data.id);
      if (this.props.cancelResponse.data.status === "inactive") {
        toast.info("Zamówienie zostało anulowane", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
    if (prevProps.retry !== this.props.retry) {
      let retry = this.props.retry;
      if (typeof window !== `undefined`)
        window.location.href = retry.redirectUri;
    }
    if (prevProps.setActive !== this.props.setActive){
      if(this.props.setActive.data){
        navigateTo('/checkorder')
      }
    }
    if (prevProps.createOrder !== this.props.createOrder){
      if(this.props.createOrder.data){
        let order = this.props.createOrder.data;
        localStorage.setItem('orderCode', order.code);
        localStorage.setItem('order', 'normal');
        navigateTo('/checkorder')
      }
    }
  }

  triggerCancelOrder(code) {
    const formData = {
      code: btoa(code)
    };
    this.props.cancelOrder(formData);
  }

  continueOrderRedirect(orderCode, surveyLines) {
    let type = 'normal';
    if(surveyLines.length > 0){
      type = 'survey';
      localStorage.setItem('surveyOrderCode', orderCode);
    } else {
      localStorage.setItem('orderCode', orderCode);
    }
    localStorage.setItem('order', type);
    navigateTo('/checkorder')
  }

  createOrderByOrder(orderCode){
    this.props.createOrderByOrder(btoa(orderCode));
  }

  checkIfPossibleToRetryPayment(status, provider, code) {
    if (status === 'waiting_payment' && provider === 'payu')
      return (
        <Grid.Column textAlign='center' verticalAlign='middle'>
          <Button primary onClick={this.retryPayment.bind(this, code)}>Ponów płatność</Button>
        </Grid.Column>
      )
  }

  retryPayment(code) {
    code = btoa(code);
    this.props.retryPayuPayment(code)
  }

  render() {
    if (this.state.loading) {
      return (
        <Segment style={{width: "100%", height: "100vh"}} loading={true}/>
      )
    } else {
      if (!this.state.isEmpty) {
        return (
          <MainTopBg style={{backgroundSize: 'contain', backgroundPosition: 'top'}}>
            <Segment basic style={{minHeight: "calc(100vh - 10px)", paddingTop: "150px", marginTop: 0}}>
              <Helmet>
                <meta charSet="utf-8"/>
                <title>Pure4me - Historia zamówień</title>
              </Helmet>
              <Container>
                <Header as='h1' textAlign="center" style={{marginBottom: 50}}>{t('Historia zamówień')}</Header>
                <Grid divided columns={7} centered stackable>
                  {_.map(this.state.orders, ({created_at, user, status, orderLines, code, provider, surveyLines}, i: number) => (
                    <React.Fragment key={i}>
                      <Grid.Row>
                        <Grid.Column>Numer zamówienia: <br/>{i + 1}</Grid.Column>
                        <Grid.Column>Data: <br/>{created_at.substr(0, 10)}</Grid.Column>
                        <Grid.Column>E-mail: <br/><span style={{wordWrap: "break-word"}}>{user}</span></Grid.Column>
                        <Grid.Column>{status}</Grid.Column>
                        <Grid.Column textAlign='center' verticalAlign='middle'>
                          <Button disabled={status === "inactive"} onClick={() => {
                            if (status === "active") {
                              toast.error(<div>
                                <p>Zamówienie jest aktywne</p>
                                <p>W celu anulowania skontaktuj się z nami mailowo lub telefonicznie</p>
                              </div>, {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                              });
                            } else {
                              this.triggerCancelOrder(code)
                            }
                          }}>anuluj</Button>
                        </Grid.Column>
                        <Grid.Column textAlign='center' verticalAlign='middle'>
                          <Button primary onClick={this.createOrderByOrder.bind(this, code)}>Ponów zamówienie</Button>
                        </Grid.Column>
                        <Grid.Column textAlign='center' verticalAlign='middle'>
                          <Button disabled={status === "inactive"} primary onClick={this.continueOrderRedirect.bind(this, code, surveyLines)}>Kontynuuj zamówienie</Button>
                        </Grid.Column>
                        {this.checkIfPossibleToRetryPayment(status, provider, code)}
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16} textAlign="center" style={{textTransform: "lowercase"}}>
                          {_.map(orderLines, ({name}, i: number) => (
                            <React.Fragment key={i}>
                              <span> {name} {(i < orderLines.length - 1) && "|"}</span>
                              {(i === orderLines.length - 1) && <><Divider section hidden/></>}
                            </React.Fragment>
                          ))}
                        </Grid.Column>
                      </Grid.Row>
                      <Divider hidden/>
                    </React.Fragment>
                  ))}
                </Grid>
              </Container>
            </Segment>
          </MainTopBg>
        )
      } else {
        return (
          <Segment basic style={{minHeight: "calc(100vh - 10px)", paddingTop: "150px"}}>
            <Container>
              <Header as='h1' textAlign="center">{t('Historia zamówień')}</Header>
              <Header as='h3' textAlign="center">Twoja historia zamówień jest pusta. Może rozważysz złożenie zamówienia?</Header>
            </Container>
          </Segment>
        )
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);